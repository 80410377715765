<template>
  <div>
    <nav-bar>
      <template v-slot:left>
        <div class="logo" @click="toIndex">
          <nav-bar-logo font="font-size: 26px;"/>
        </div>
      </template>
      <template v-slot:right>
        <div class="navbar-r"><router-link to="/index">{{$store.getters.getSiteInfo.name}}首页</router-link></div>
      </template>
    </nav-bar>
    <div class="user-layout">
      <div class="register-body">
        <div class="title"><router-link to="/index">欢迎注册{{$store.getters.getSiteInfo.name}}</router-link></div>
        <sign-up-input @form-data="formData" @get-code="getCode" :numOrSms="false" :showRegisterPwd="true"/>
        <div style="font-size: 12px;color: #888;text-indent: 5px;margin-top: -10px;"><el-checkbox v-model="checked"></el-checkbox><span style="margin-left: 5px;">勾选即同意该<a href="/xieyi" target="_blank" style="color: #409EFF;">《用户使用协议》</a></span></div>
        <!-- 立即注册 -->
        <div class="btn-box">
          <el-button class="btn" @click="register" :disabled="!(this.getFormData.accountBoole && this.getFormData.passwordBoole && this.getFormData.codeBoole)">立即注册</el-button>
        </div>
        <!-- 立即登录 -->
        <div class="gologin">
          已有账号， <router-link to="/login">立即登录？</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar.vue'
import NavBarLogo from 'components/navbar/NavBarLogo.vue'
import SignUpInput from 'components/signUpInput/SignUpInput.vue'
import { getCode, register } from 'network/api'

export default {
  components: {
    NavBar,
    NavBarLogo,
    SignUpInput
  },
  data () {
    return {
      getFormData: {
        account: '',
        password: '',
        code: '',
        accountBoole: null,
        passwordBoole: null,
        codeBoole: null
      },
      invitedCode: '',
      clickDisabled: true,
      checked: false
    }
  },
  mounted () {
    console.log(this.$route.query)
    if (this.$route.query && this.$route.query.invitedCode) {
      this.invitedCode = this.$route.query.invitedCode
    }
    window._agl && window._agl.push(['track', ['success', { t: 18 }]])
  },
  methods: {
    toIndex () {
      this.$router.push('index')
    },
    // 监听子组件获取input值
    formData (value) {
      this.getFormData = value
    },
    // 监听子组件获取验证码
    getCode (value) {
      getCode(this.getFormData.account, 'register').then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        })
      })
    },
    // 注册
    register () {
      if (this.clickDisabled === false) {
        return
      }
      if (!this.checked) {
        this.$message.error('请先阅读并同意本站用户使用协议')
        return
      }
      this.clickDisabled = false
      setTimeout(() => {
        this.clickDisabled = true
      }, 5000)
      const key = this.$configList[window.location.host]
      register(this.getFormData.account, this.getFormData.password, this.getFormData.code, key, this.invitedCode, this.getFormData.prPhone).then(res => {
        if (res.code === 0) {
          this.$message.error(res.msg)
        } else if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          const _this = this
          // 注册成功跳转到登录页
          setTimeout(function () {
            _this.$router.push('/amongPage')
          }, 1500)
        }
        this.clickDisabled = true
      })
    }
  }
}
</script>

<style scoped src="styles/views/register/register.css">
</style>
